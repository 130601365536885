@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("./fonts/Inter-SemiBold.woff");
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("./fonts/Inter-Medium.woff");
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("./fonts/Inter-Regular.woff");
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input {
  font-family: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

i.eva-hover {
  display: inline-flex;
}

.dafunnels-sortable-question {
  pointer-events: auto !important;
}
